import {useEffect, RefObject} from "react";

type Event = MouseEvent | TouchEvent

export function useOnClickOutside<T extends HTMLElement>(ref: RefObject<T>, handler: (event: Event) => void) {
    useEffect(
        () => {
            const listener = (event: Event) => {
                const el = ref?.current
                if (!el || el.id === 'nav-toggle' || el.contains((event?.target as Node) || null)) return
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    );
}